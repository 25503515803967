import io from '@feathersjs/socketio-client';
const ioClient = require('socket.io-client');
const feathers = require('@feathersjs/client');

/**
 * Setup the FeathersJS client connection. 
 * This isn't an entirely obvious process: 
 * we need to initialize a base socket connection
 * with the API URL. This is "ioClient" from core
 * socket IO.
 * 
 * Then we need to configure Feathers with this 
 * base socket connection so all the FeathersJS
 * messaging elements are wrapped. 
 */

// Setup the base socket:
const socket = ioClient(process.env.REACT_APP_API_URL);

// Creat the Feathers client:
const feathersClient = feathers();

// Configure the Feathers Client by initializing
// feathersjs/socketio-client with the base socket:
feathersClient.configure(io(socket));

// Configure auth:
feathersClient.configure(feathers.authentication());

feathersClient.service('reporting/summing').timeout = 30000;
feathersClient.service('reporting/staff-duration').timeout = 30000;
feathersClient.service('reporting/interaction-times').timeout = 30000;
feathersClient.service('reporting/event-summary').timeout = 30000;

// Create a global error handler:
feathersClient.hooks({
  error(context:any) {
    
    if (context.error.code === 401){
      console.log(window.location);
      if (window.location.pathname.indexOf('login') === -1 ){
        window.location.href = ('/login?state=expired');
      }
      return;
    }

    console.error(`Global Error in '${context.path}' service method '${context.method}'`, context.error.stack);
   // debugger;
    switch (window.location.pathname)
    {
     
    }  
    
    if (process.env.REACT_APP_API_URL !== 'http://localhost:3030' ){
    //  window.location = ('/login?state=expired');
    }
    
  }
});

export default feathersClient;
